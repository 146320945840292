import 'core-js/es6';
import 'core-js/es7/reflect';
import 'intl';
import 'intl/locale-data/jsonp/en';

if (process.env.ENV === 'prod') {
  // Production
} else {
  // Development
  // tslint:disable-next-line
  Error['stackTraceLimit'] = Infinity;
  require('zone.js/dist/long-stack-trace-zone');
}
